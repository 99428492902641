import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro } from '../content/blog'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import BlogSearch from '../sections/blog/BlogSearch'
import FormNewsletter from '../components/FormNewsletter'

const Blog = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/blog.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/blog.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} h={1} logo rounded />
      <BlogSearch />
      <FormNewsletter />
    </Layout>
  )
}

export default Blog
